<template>
<div class="page-content w-100">
  <!-- start heading -->
  <div class="row">
    <div class="col-12 col-lg-10">
      <p class="text-center page-heading">Library</p>
    </div>
    <div class=" d-none d-lg-block col-lg-2 ">
    <div>
    <img src="@/assets/images/settings.png" class="settingsImg" alt="RxIx"   @click="settings()"/>
    </div>
    <div class="text-align text-center">
    <label class="updateSettingText">Update settings</label>
    </div>

    </div>
  </div>
  <div class="row d-lg-none">
    <div class="col-8"></div>
    <div class="col-4 mt-3" >
    <div>
    <img src="@/assets/images/settings.png" class="settingsImg" alt="RxIx"   @click="settings()"/>
    </div>
    </div>
  </div>

  <!-- end heading -->
  <div class="row px-2 ">
    <div class="col-md-12 col-xs-12">
      <ul class="nav nav-pills mb-3 nav-justified" id="pills-tab" role="tablist">
        <!-- <li class="nav-item" role="presentation">
          <button class="nav-link text-color active text-20 font-weight-bold" id="pills-anatomyIllustrations-tab" data-bs-toggle="pill" data-bs-target="#pills-anatomyIllustrations" type="button" role="tab" aria-controls="pills-anatomyIllustrations" aria-selected="false">Resources</button>
        </li> -->
        <!-- <li class="nav-item" role="presentation">
          <button class="nav-link text-color text-20 font-weight-bold" id="pills-dssCharts-tab" data-bs-toggle="pill" data-bs-target="#pills-dssCharts" type="button" role="tab" aria-controls="pills-dssCharts" aria-selected="false">Illustrations</button>
        </li> -->
        <!-- <li class="nav-item" role="presentation">
          <button class="nav-link text-color text-20 font-weight-bold" id="pills-standardTreatment-tab" data-bs-toggle="pill" data-bs-target="#pills-standardTreatment" type="button" role="tab" aria-controls="pills-standardTreatment" aria-selected="false">STG</button>
        </li> -->
        <!-- <li class="nav-item" role="presentation">
          <button class="nav-link text-color text-20 font-weight-bold" id="pills-infoDisorder-tab" data-bs-toggle="pill" data-bs-target="#pills-infoDisorder" type="button" role="tab" aria-controls="pills-infoDisorder" aria-selected="false">Info on disorders</button>
        </li> -->
      </ul>
      <div class="tab-content" id="pills-tabContent">
        <div class="tab-pane fade show active" id="pills-anatomyIllustrations" role="tabpanel" aria-labelledby="pills-anatomyIllustrations-tab">
          <ResourceInvite></ResourceInvite>
        </div>
        <!-- <div class="tab-pane fade" id="pills-dssCharts" role="tabpanel" aria-labelledby="pills-dssCharts-tab">

        </div> -->
        <!-- <div class="tab-pane fade show" id="pills-standardTreatment" role="tabpanel" aria-labelledby="pills-standardTreatment-tab">

        </div> -->
        <!-- <div class="tab-pane fade" id="pills-infoDisorder" role="tabpanel" aria-labelledby="pills-infoDisorder-tab">

        </div> -->
      </div>
    </div>
  </div>

  <div class="d-block d-lg-none p-3">
     <div class="row">
       <div class="col-12">
          <ul class="list-group">
            <!-- <li class="list-group-item disabled" aria-disabled="true">
              <h5 class='font-weight-bold text-color'><route-link to="">Resources</route-link></h5>
            </li> -->
            <!-- <li class="list-group-item">
              <h5 class='font-weight-bold text-color'><route-link to="">Illustrations</route-link></h5>
            </li> -->
            <!-- <li class="list-group-item">
              <h5 class='font-weight-bold text-color'><route-link to="">STG</route-link></h5>
            </li> -->
            <!-- <li class="list-group-item">
              <h5 class='font-weight-bold text-color'><route-link to="">Info on disorders</route-link></h5>
            </li> -->
          </ul>
       </div>
     </div>
  </div>
</div>
<userfooter></userfooter>
</template>
<script>
import userfooter from "../user-footer.vue"
import ResourceInvite from "../library/resource-invite.vue"
export default {
  components: {
    ResourceInvite,
    userfooter

  },
  data() {
    return {}
  },
  created: function () {},
  computed: {},
  methods: {
    async settings() {
        const query = this.$route.query;
        this.$router.push({ path: `/user/settings`, query });  
      },
  }
}

</script>
<style>
  .settingsImg{
      width: 40px;
      height: 40px;
      cursor: pointer;
      margin-left: auto;
      margin-right: auto;
      display: block;
  }
  
@media screen and (max-width:435px) {
    .updateSettingText{
font-size: 11px;
}
.settingsImg {
    width:30px;
    height: 30px;
}
}
</style>
