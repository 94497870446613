<template>
    <div>
        <section class="banner-two2 img-banner-white">
            <div class="container-fluid text-center text-align">
                <div class="select-specility ">
                    <h2>Select the first letter of the disease or condition.</h2>
                </div>
                <div class="d-none d-lg-block">
                    <div class="boxing-text p-3 mt-3">
                        <div class="flex">
                            <div class="letters-wrap" v-for="letter in firstOrderletter" :key="letter">
                                <div class="box-letter" @click="accessReliable(letter)">{{ letter }}</div>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="letters-wrap" v-for="letter in secondOrderletter" :key="letter">
                                <div class="box-letter" @click="accessReliable(letter)">{{ letter }}</div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="d-block d-lg-none">
                    <div class="boxing-text p-3 mt-3">
                        <div class="flex">
                            <div class="letters-wrap" v-for="letter in AtoIletter" :key="letter">
                                <div class="box-letter" @click="accessReliable(letter)">{{ letter }}</div>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="letters-wrap" v-for="letter in JtoRletter" :key="letter">
                                <div class="box-letter" @click="accessReliable(letter)">{{ letter }}</div>
                            </div>
                        </div>
                        <div class="flex">
                            <div class="letters-wrap" v-for="letter in StoZletter" :key="letter">
                                <div class="box-letter" @click="accessReliable(letter)">{{ letter }}</div>
                            </div>
                        </div>
                    </div>
                </div>

                <div class="row mt-3" style="margin: auto;" v-if="!openRes">
                    <div class="card card-box white-box">
                        <div v-for="index in value" :key="index">
                            <h2 class="disorders" @click="openResource(index)" style="margin-top: 5px; margin-left: 10px;">
                                {{ index.topic }}
                            </h2>
                        </div>
                    </div>
                </div>

                <div v-if="ope">
                    <div v-if="value.length === 0">
                        <h2 class="disorders" @click="openResource(index)"
                            style="margin-top: 5px; margin-left: 10px;color:rgb(239, 63, 63)">
                            No topics available!
                        </h2>
                    </div>
                </div>

                <div class="row mt-3" style="margin: auto;" v-if="openRes">
                    <div class="card card-box white-box1">
                        <div class="flex">
                            <h2 class="col-11 disorders" v-for="index in dataValues" :key="index">{{
                                index.topic }}</h2>
                          <p class="mt-0 closeButton" @click="closeRes()"><font-awesome-icon :icon="['fas','times']" /></p>
                        </div>
                        <hr class="mobilemargin" />
                        <p class="paradatas" v-for="index in dataValue" :key="index"
                            style="margin-top: 5px; margin-left: 10px;" @click="openSignup(index)">{{ index.resource_name }}
                        </p>
                    </div>
                </div>
            </div>
        </section>
      
    </div>
</template>
<script>

import axios from "axios"
export default {
    components: {
    
    },
    data() {
        return {
            issign: false,
            seen: true,
            hcpDoctors: [],
            value: [],
            valueData: [],
            dataValue: [],
            showTopic: false,
            showResource: false,
            openRes: false,
            url: '',
            resUrl: '',
            ope: false,
            disorderId: [],
            getDisorderTopic:[],
            getPatientTopic:[],
            getPatientTopicName:[]
        }
    },
    created: function () {
        this.queryParameters = this.$route.query;
        this.getHCPDoctors()
        this.userSign()
    },
    computed: {
        firstOrderletter() {
            let letters = []
            for (let i = "A".charCodeAt(0); i <= "M".charCodeAt(0); i++) { letters.push(String.fromCharCode([i])) }
            return letters
        },
        secondOrderletter() {
            let lettersData = []
            for (let i = "N".charCodeAt(0); i <= "Z".charCodeAt(0); i++) { lettersData.push(String.fromCharCode([i])) }
            return lettersData
        },
        AtoIletter() {
            let letters = []
            for (let i = "A".charCodeAt(0); i <= "I".charCodeAt(0); i++) { letters.push(String.fromCharCode([i])) }
            return letters
        },
        JtoRletter() {
            let lettersData = []
            for (let i = "J".charCodeAt(0); i <= "R".charCodeAt(0); i++) { lettersData.push(String.fromCharCode([i])) }
            return lettersData
        },
        StoZletter() {
            let lettersData = []
            for (let i = "S".charCodeAt(0); i <= "Z".charCodeAt(0); i++) { lettersData.push(String.fromCharCode([i])) }
            return lettersData
        },
    },
    methods: {
        updateSettings() {
            this.$router.push('/user/topic')
        },
     
        getHCPDoctors() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .get(`${BASE_API_URL}/hcp-types?hcpTypes=doctor`)
                .then((response) => {
                    this.hcpDoctors = response.data;
                })
        },
        getTopics() {
            this.showTopic = true
        },
        getResource() {
            this.showResource = true
        },
        accessReliable(letter) {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            this.ope = true;
            let disorderId =  this.getPatientTopicName.concat (this.getDisorderTopic);
            
            if (disorderId) {
                this.url = `${BASE_API_URL}/topic-disorders?q=${letter}&disorderId=${disorderId}`
            } else {
                this.url = `${BASE_API_URL}/topic-disorders?q=${letter}`
            }
            axios
                .get(this.url)
                .then(async (response) => {
                    this.value = response.data;
                    let result = response.data.filter(obj => obj.topic.startsWith(letter));
                    this.value = result
                })
                .catch(error => {
                    console.error("Error ", error);
                    this.value = [];
                });
        },
        openResource(index) {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            this.openRes = true;
            axios
                .get(`${BASE_API_URL}/res-url?q=${index.topic}`)
                .then((response) => {
                    this.dataValue = response.data
                    this.dataValues = [response.data[0]]
                })
        },
        openResources(index) {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            this.openRes = true;
            axios
                .get(`${BASE_API_URL}/res-url?q=${index}`)
                .then((response) => {
                    this.dataValue = response.data
                    this.dataValues = response.data[0].topic
                })
        },
        closeRes() {
            this.openRes = false;
        },
        openSignup(index) {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .get(`${BASE_API_URL}/res-url?q=${index.topic}`)
                .then((response) => {
                    response.data.forEach((data) => {
                        if (index.id === data.id) {
                            this.dataUrl = data.resource_url;
                            window.open(this.dataUrl)
                        }
                    })
                })
                .catch((error) => {
                    console.error('Error fetching resource URL:', error);
                });
        },
        userSign() {
            const BASE_API_URL = process.env.VUE_APP_BASE_APIURL
            axios
                .get(`${BASE_API_URL}/public-user/${localStorage.getItem('publicSignUpData')}/one`)
                .then((response) => {
                    if (response?.data) {
                        this.userRecords = response.data;
                        const disorderTopic= this.userRecords.getDisordersId
                        disorderTopic.forEach((Disorder)=>{
                            const topics =Disorder.disorder
                            this.getDisorderTopic.push(topics)
                            return topics
                        })
                        
                        const patientDisorder = response.data.publicPatientId;
                        patientDisorder.forEach(topic => {
                            const patientTopics = topic.specialities;
                            this.getPatientTopic= this.getPatientTopic.concat(patientTopics);
                        });
                        this.getPatientTopic.map((getName)=>{
                        const getTopicName = getName.name
                        this.getPatientTopicName.push(getTopicName)
                    }) 
                    }
                })
                .catch(function (error) {
                    console.log(error?.response);
                });
        }
    }
}
</script>
<style>
    .select-specility h2 {
        font-size: 28px;
    }
hr.mobilemargin {
    margin-top: 0px;
}

.disorders {
    height: auto;
    margin-left: 10px;
    margin-top: 5px;
    font-size: 22px;
}

.boxing-text {
    width: 65%;
    margin-left: auto;
    margin-right: auto;
    /* border: 1px solid #00979e; */
}

.box-letter {
    border: 1.5px solid #00979e;
    width: 30px;
    height: 30px;
    /* margin: 10px; */
    margin-left: 10px;
    color: gray;
    /* text-decoration: underline; */
    cursor: pointer;

}

.settings-icon {
    width: 50px;
    margin-top: 10px;
}

.hr-border.line {
    border-bottom: 3px solid #00979e;
}

.navbarMobile {
    border: 1px solid #fff;
    position: sticky;
    bottom: 0;
    background: #fff;
    width: 100%;
    z-index: 999;
}

.item-Center {
    align-items: center;
    display: flex;
    justify-content: space-evenly;
}

.mobileNavbar-image {
    width: 50px;
    height: 50px;
    /* margin-top: 6px; */
}

.imgCenter {
    display: block;
    margin: auto;
}

p.mobileNavbarText {
    margin-left: auto;
    margin-right: auto;
    color: #707070;
    font-size: 17px;
    margin-top: 0px;
    font-weight: bold;
}

.mobileButton {
    height: 75px;
    width: 75px;
    background-color: #00979e;
    border-radius: 50%;
    display: inline-block;
    text-align: center;
    color: #fff;
    padding-top: 26px;
    font-size: 17px;
    font-weight: bold;
    box-shadow: 2px 2px 3px 2px rgba(50, 50, 50, 0.75);
}

.banner-sub-content {
    margin-top: 20px;
}

.drp-speciality select {
    background-color: #fff;
    color: #4d4d4d;
    border-color: #999999;
    width: 194px;
    height: 35px;
    border-radius: 8px;
    font-size: 21px;
}

/* .drp-topic select {
    background-color: #fff;
    color: #4d4d4d;
    border-color: #999999;
    width: 194px;
    height: 35px;
    border-radius: 8px;
    font-size: 21px;
    margin-left: 44px;
    margin-right: 44px;
  } */

.go-btn button {
    color: #fff;
    background-color: #34989f;
    outline: none;
    border: none;
    font-weight: bold;
    font-size: larger;
    border-radius: 12px;
    width: 72px;
    height: 37px;
    margin-left: 30px;
}

.flex {
    display: flex;
    justify-content: center;
    padding-top: 10px;
}

.card.card-box.white-box {
    width: 60%;
    margin: auto;
    margin-bottom: 30px;
    /* height: 100vh; */
    /* height: 100%; */
    border-color: #34989f;
    text-align: start;
    cursor: pointer;

}

.card.card-box.white-box1 {
    width: 60%;
    margin: auto;
    margin-bottom: 30px;
    /* height: 100vh; */
    height: 100%;
    border-color: #34989f;
    text-align: start;
    cursor: pointer;

}

select.form-control.drp {
    width: 30%;
    font-size: 17px;
    margin-left: auto;
    margin-right: auto;
    margin-top: 10px;
}


.heading-information h1 {
    text-align: center;
    color: #000000;
    font-weight: bold;
}

.heading-information {
    margin-top: 20px;
}

/* .reliable-information {
    display: flex;
    justify-content: center;
  }
  
  .reliable-information-para {
    font-size: 30px;
    color: #4d4d4d;
    width: 47%;
  } */

.select-specility {
    color: #000;
    /* margin-top: 3rem; */
    text-align: center;
}

nav.three-nav1 {
    float: right;
    position: fixed;
    right: -3px;
    background: transparent;
}

nav.three-nav1:hover {
    transform: perspective(400px) rotateY(1deg);
}

nav.three-nav1 ul.three-nav1 {
    text-align: center;
    margin-top: 30px;
}

@media screen and (max-width:992px) {

    nav.three-nav1 ul.three-nav1 {
        text-align: center;
        margin-top: 0px
    }
}

nav.three-nav1 ul.three-nav1 li.three-nav1 {
    position: relative;
    width: 71px;
    cursor: pointer;
    background: cadetblue;
    text-transform: uppercase;
    transition: all .4s ease-out;
    list-style: none;
}

nav.three-nav1 ul.three-nav1 li:after {
    position: absolute;
    background: white;
    color: crimson;
    top: 0;
    left: 0px;
    width: 70px;
    height: 100%;
    opacity: .5;
    transform: perspective(400px) rotateY(90deg);
    transform-origin: 0 100%;
    transition: all .4s ease-out;
}

nav.three-nav1 ul.three-nav1 li:nth-child(1):after {
    line-height: 88px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(2):after {
    line-height: 88px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(3):after {
    line-height: 85px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(4):after {
    line-height: 70px;
}

nav.three-nav1 ul.three-nav1 li:nth-child(5):after {
    line-height: 85px;
}

nav.three-nav1 ul.three-nav1 li:hover {
    transform: translateX(0px);
    background-color: #fff;
}

nav.three-nav1 ul.three-nav1 li:hover:after {
    opacity: 1;
    transform: perspective(400px) rotateY(90deg) scale(1);
}


nav.three-nav1 ul.three-nav1 li>div {
    display: inline-block;
    padding: 25px 0;
    background: transparent;
}

nav.three-nav1 ul.three-nav1 li.three-nav1 div {
    position: relative;
}


li.navbar-nav-list.hidden-md.hidden-lg.dropdown.xs-design.open-side-menu1 {
    list-style: none;
    position: relative;
    left: 35px;
    bottom: 20px;
}

section.banner-two2.img.banner {
    margin-top: 4.5rem;
    background-repeat: no-repeat;
    background-image: url("~@/assets/images/cards-banner7.png");
    background-size: 3500px 593px;
    /* transform: rotate(90deg);  */
    padding: 30px 0px 50px 0px;
}

section.banner-two2.img-banner-white {
    background-color: #fff;
    background-repeat: no-repeat;
}

.navbar-light .navbar-nav .nav-link {
    color: #00979e;
}

@media screen and (max-width:991px) {

    .paradatas {
        margin-bottom: 5px;
    }
    h2.disorders {
        font-size: 20px;
    }

    .card.card-box.white-box1 {
        width: 100%;
    }

    .card.card-box.white-box {
        width: 100%;
    }

    section.banner-two2.img.banner {
        margin-top: 0rem;
    }

    .heading-information h1 {
        font-size: 33px;
    }

    .accesinformationheadingText h3 {
        font-size: 22px;
    }

    .select-specility {
        margin-top: 2rem;
    }

    .select-specility h2 {
        font-size: 25px;
    }
}

@media screen and (max-width: 576px) {
    .heading-information h1 {
        font-size: 25px;
    }

    .accesinformationheadingText h3 {
        font-size: 17px;
    }

    .select-specility {
        margin-top: 1.5rem;
    }

    .select-specility h2 {
        font-size: 20px;
    }

    section.banner-two2.img.banner {
        padding: 10px 0px 24px 0px;
    }
}

@media screen and (max-width: 560px) {

    .mobileNavbar-image {
        width: 35px;
        height: 35px;
    }

    p.mobileNavbarText {
        margin-left: auto;
        margin-right: auto;
        color: #707070;
        font-size: 13px;
        margin-top: 0px;
    }

    .mobileButton {
        height: 55px;
        width: 55px;
        font-size: 13px;
        padding-top: 18px;
    }
}

@media screen and (max-width:550px) {
    .banner-sub-content {
        margin-top: 10px;
    }

    section.banner-two2.img.banner {
        padding: 10px 0px 24px 0px;
    }
}

@media screen and (max-width:420px) {
    .box-letter {
        font-size: 15px;
        width: 23px;
        height: 23px;
    }
}
</style>